/**/
/* Global Styles */
/**/

body {
  -webkit-font-smoothing: antialiased;
  background-color: rgba(244, 241, 236, 1);
}

a {
  text-decoration: none;
  color: #555857;
}

a:hover {
  text-decoration: none;
  color: #555857;
}

.ReactModal__Body--open {
  overflow: hidden;
}

/**/
/* Form Styles */
/**/

input,
textarea,
select {
  font-family: 'mrs-eaves-xl-serif', Georgia, 'Times New Roman', Times, serif;
}

label {
  margin-bottom: 0px;
}

.form-control:focus,
.custom-control-input:focus ~ .custom-control-label::before {
  border-color: #a99f85;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-control-label::before {
  top: 0.025rem;
}
.custom-control-label::after {
  top: 0.05rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #a99f85;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* // NOTE: the order of these styles DO matter */
.DayPicker_weekHeader_ul,
.DayPicker_weekHeader_li {
  margin: 0;
  padding: 0;
}
.DayPicker {
  z-index: 999;
  font-family: 'mrs-eaves-xl-serif', Georgia, 'Times New Roman', Times, serif !important;
}
.DateRangePickerInput,
.SingleDatePickerInput {
  border: 1px solid #d8d8d8 !important;
  border-radius: 0;
}
.left-wrapper {
  background-color: #efefef;
}
.left-p .DateInput {
  width: 105px !important;
}
.left-p .DateInput_input {
  font-size: 16px !important;
}
.DayPickerNavigation_button__default {
  border: 1px solid #000 !important;
  background-color: #fff;
  color: #000 !important;
}

.DateInput__input {
  margin-bottom: 0;
  border: none !important;
}
.DateInput_input__focused {
  border-bottom-color: #a99f85 !important;
}
.CalendarDay {
  text-align: center;
}

.CalendarDay__highlighted_calendar {
  background: #88af99 !important;
  color: #fff !important;
  border: 1px double #e4e7e7 !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #565857 !important;
  border: 1px double #565857 !important;
  color: #fff !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #d8d8d8 !important;
  border-color: #d8d8d8 !important;
  color: rgba(85, 88, 87, 0.65) !important;
}

.CalendarDay__selected_span {
  background: #e4e7e7 !important;
  border: 1px double #e4e7e7 !important;
  color: #484848 !important;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: rgba(169, 159, 133, 0.5) !important;
  border: 1px double rgba(169, 159, 133, 0.5) !important;
}

.CalendarMonth_caption strong {
  font-weight: normal;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  /* background: #cacccd; */
  border: 1px solid #cacccd;
  color: #82888a;
  box-sizing: border-box !important;
  font-size: 14px !important;
  text-align: center !important;
  cursor: not-allowed !important;
  color: rgb(202, 204, 205) !important;
  background: rgb(255, 255, 255) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(228, 231, 231) !important;
  border-image: initial !important;
}

.DayPickerNavigation_button__default {
  border-color: transparent;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #a99f85 !important;
  color: #ffffff !important;
}

/*  */
/* React-Modal Animation */
/*  */

.ReactModalPortal {
  position: relative;
}
.ReactModal__Overlay {
  z-index: 9999;
}

.ReactModal__Content {
  opacity: 0;
  transform: translate(-50%, -50%);
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translate(-50%, -50%);
}

/* Modal content */
.modalContent a {
  color: #555857;
  text-decoration: none;
}
.modalContent a:hover {
  text-decoration: underline;
}

.modalContent a .noline {
  text-decoration: none;
  border: none;
}

.modalContent a:hover .noline {
  text-decoration: none;
  border: none;
}

/*  */
/* Text Styles */
/*  */
ul {
  margin: 0;
  padding: 0;
  margin-left: 0.25rem;
  margin-bottom: 2rem;
}

ul li {
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

/* Results Page */
.resultsLayout {
  margin-right: 0;
}
@media (min-width: 992px) {
  .resultsLayout {
    margin-right: 2rem;
  }
}

.best-match {
  background-color: rgba(237, 191, 88, 0.5) !important;
}

.other-match {
  background-color: rgba(195, 188, 185, 0.3) !important;
}

.match {
  background-color: rgba(136, 175, 153, 0.45) !important;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  text-decoration: line-through;
}
