/* autoprefixer grid: autoplace */
.calendar-container {
  width: 100%;

  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  padding-bottom: 200px;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 95px);
}

.no-overflow {
  overflow: hidden;
}

.calendar-container .loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
}

.left-p {
  height: calc(100vh - 95px);
}

.nobar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.nobar::-webkit-scrollbar {
  width: 0 !important;
}

.left-panel {
  grid-column-start: 1;
  grid-row-start: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  box-shadow: 3px 0 0 rgba(0, 0, 0, 0.03);
  position: sticky;
  left: 0;
  z-index: 5;
  background: white;
  margin-top: -50px;
}

.scrollArrows {
  height: 50px;

  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
}
.right-panel {
  grid-column-start: 2;
  grid-row-start: 1;
}

.placeholder {
  width: 2000px;
  height: 100%;
  background: teal;
}

.room-header {
  height: 50px;
  font-weight: bold;
  font-size: 13px;
}

.room-header > div {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}

.room-item {
  height: 35px;
  font-size: 13px;
}

.room-item > div {
  height: 35px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eee;
}

.room-item:last-child > div {
  border-bottom: none;
}

.calendar-header {
  height: 50px;
  display: flex;
}

.header-cell {
  height: 51px;
  width: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;

  font-size: 10px;
  z-index: 1;
  background: white;
}

.header-cell > span {
  text-transform: uppercase;
}

.header-lodge {
  border-right: none;
  background: #efefef !important;
}

.header-cell-day {
  color: #70757a;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
}

.header-cell-label {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  width: max-content;
  min-width: 24px;
  color: #3c4043;
  line-height: 16px;
}

.dates > .header-cell {
  border-top: none;
  border-bottom: 1px solid #ccc;
}

.entry-cell {
  height: 34px;
  width: 50px;
  min-width: 50px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  position: relative;
  margin-top: 1px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entry-cell.cell-success {
  background: #a9c47f;
  border-color: #ffffff;
  color: #fff;
}

.entry-cell.cell-empty {
  background: #b6533e;
  border-color: #ffffff;
  color: #fff;
}

.entry-cell > span {
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 1px;
  bottom: 1px;
  right: 1px;
}

.entry-cell:hover > span {
  opacity: 1;
  outline: solid 1px teal;
}

.calendar-row {
  height: 35px;
  display: flex;
}

.weekend {
  background: #fafafa;
}

.today {
  background: #f6fff5;
}

.control {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.control > span {
  display: inline-block;
  text-align: center;
  padding: 10px;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
}

.grid {
  display: grid;
  grid-template-columns: 25% 80%;
  grid-template-rows: 1fr;
  position: relative;
}

.sticky {
  position: sticky;
  top: 0;
  padding-left: 25%;
  z-index: 5;
  background: white;
}

.view-folder__item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0.5rem;
  margin: 0;
  border: 1px solid transparent;
  cursor: pointer;
}

.view-folder__list {
  margin: 0;
  margin-bottom: 10px;
  min-height: 2px;
  max-height: 150px;
  overflow: hidden;
}

.view-folder__item span {
  color: #2a3039;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block {
  display: inline-block;
  border-left: 16px solid transparent;
  padding-left: 10px;
  margin-right: 15px;
}

.block-success {
  border-color: #a9c47f;
}

.block-danger {
  border-color: #b6533e;
}

.block-none {
  border-width: 0;
}

.DateRangePicker_picker {
  z-index: 300;
}
